/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

.courrier-list li {
  cursor: pointer;
}

.courrier-list :hover {
  background-color: aliceblue;
}

.table {
  table-layout: fixed;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: blue;
}


/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;

}


*::-webkit-scrollbar-thumb {
  background-color: #0692D8;
  border-radius: 5px;
  border: 1px solid white;
}

.radius-10 {
  border-radius: 20px;
}

a {
  cursor: pointer;
}

.success-icon {
  color: green;
}

.warning-icon {
  color: darkred;
}

.date-cursor {
  cursor: pointer;
}

